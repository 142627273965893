




































import { Component, Vue, Prop } from 'vue-property-decorator'

import ReportPickerModalOptions from '@/app_code/Modals/Options/ReportPickerModalOptions'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'

@Component
export default class ReportPickerModal extends Vue {
  @Prop()
  private options!: ReportPickerModalOptions;

  pickReport (report: GetReportModel) {
    if (this.options.Callback) this.options.Callback(report)

    this.$emit('close')
  }

  newReport () {
    if (this.options.NewCallback) this.options.NewCallback()
  }
}
